
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import UserService from "@/services/UserService";
import { MrUiKitButton, MrUiKitTable } from "mr-ui-kit";
import {
  PaginationModel,
  RequestResponse,
  UserRequestStatus,
  UserRole,
} from "@/interfaces";
import RequestService from "@/services/RequestService";
import FileLink from "@/components/UI/FileLink.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { saveAs } from "file-saver";
import moment from "moment";
import * as _ from "lodash";

export default defineComponent({
  name: "RequestsList",
  components: {
    MrUiKitButton,
    MrUiKitTable,
    FileLink,
  },
  setup() {
    const isMounted = ref(false);
    const requests = ref<Array<RequestResponse>>([]);
    const router = useRouter();
    const store = useStore();
    const limit = ref(10);
    const totalRequest = ref(0);
    const isLoading = ref(false);

    const userRequestStatus = store.getters.getUserRequestStatus;

    const status: {
      label: string;
      value: string;
    }[] = Object.entries(UserRequestStatus).map(([label, value]) => ({
      label,
      value,
    }));

    const requestsColumns = ref([
      {
        label: "Id",
        field: "id",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
      {
        label: "Nome",
        field: "customerName",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
      {
        label: "Bene",
        field: "service",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
      {
        label: "Esercente",
        field: "user",
        allowed: [UserRole.Admininistrator],
      },
      {
        label: "Data",
        field: "date",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
      {
        label: "Importo",
        field: "total",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
      {
        label: "Stato",
        field: "status",
        allowed: [UserRole.Dealer, UserRole.NoDealer, UserRole.Admininistrator],
      },
    ]);

    const filters = reactive({
      id: "",
      customerName: "",
      total: "",
      user: "",
      status: null,
      date: "",
      service: "",
    });

    const filterClone = ref<any>({});

    onMounted(async () => {
      await onInit();
      isMounted.value = true;
    });

    const onInit = async () => {
      pagination.current = 1;

      // console.log('date1: ', filters.date)
      // console.log('date2: ', filterClone.value.date)
      // const iso1 = filters.date === "" ? "" : new Date(filters.date).toISOString();
      // const iso2 = filterClone.value.date === "" || filterClone.value.date == null ? "" : new Date(filterClone.value.date).toISOString();
      // console.log('iso1: ', iso1)
      // console.log('iso2: ', iso2)
      // console.log('isEqualISO: ', iso1 === iso2);
      // console.log('isEqualDate: ', filters.date === filterClone.value.date);

      if (JSON.stringify(filters) === JSON.stringify(filterClone.value))
        return null;
      filterClone.value = _.cloneDeep(filters);
      filterClone.value.status = filterClone.value.status
        ? (filterClone.value.status.value as boolean)
        : null;
      totalRequest.value = await RequestService.countRequests(
        filterClone.value
      );
      pagination.count = totalRequest.value;

      await onLoad(0);
    };

    const onLoad = async (offset: number) => {
      filterClone.value = _.cloneDeep(filters);
      filterClone.value.status = filterClone.value.status
        ? (filterClone.value.status.value as boolean)
        : null;
      if (!isLoading.value) {
        isLoading.value = true;

        requests.value = await RequestService.getAllRequests({
          ...filterClone.value,
          limit: limit.value,
          offset,
        });

        isLoading.value = false;
      }
    };

    watch(filters, (newVal, oldVal) => {
      onInit();
    });

    const pagination = reactive<PaginationModel>({
      current: 1,
      count: 0,
      limit: limit.value,
    });

    const getColorType = (type: UserRequestStatus) => {
      let bgColor = "";
      let text = "";
      switch (type) {
        case UserRequestStatus.Draft: {
          text = userRequestStatus[0].label;
          bgColor = "secondary";
          break;
        }
        case UserRequestStatus.WaitingReview: {
          text = userRequestStatus[1].label;
          bgColor = "info";
          break;
        }
        case UserRequestStatus.RequestOperation: {
          text = userRequestStatus[2].label;
          bgColor = "warning";
          break;
        }
        case UserRequestStatus.Rejected: {
          text = userRequestStatus[4].label;
          bgColor = "danger";
          break;
        }
        case UserRequestStatus.Accepted: {
          text = userRequestStatus[3].label;
          bgColor = "success";
          break;
        }
      }
      return { bgColor, text };
    };

    const openRequest = (request: RequestResponse) => {
      store.commit("setRequest", request);
      router.push({ name: "request", params: { id: request.id } });
    };

    const requestExport = async () => {
      const xlsx = await RequestService.requestExport();
      var blob = new Blob([xlsx]);

      saveAs(
        blob,
        "richieste_" + moment().format("DD-MM-YY_HH-mm-ss") + ".xls"
      );
    };

    return {
      isMounted,
      isAdmin: UserService.isAdmin(),
      requests,
      getColorType,
      openRequest,
      hoverClass: {
        "background-color": "rgba(0,0,0,0.05) !important",
        cursor: "pointer",
      },
      requestsColumns: requestsColumns.value.filter((column) =>
        column.allowed.includes(UserService.getUser().role)
      ),
      pagination,
      onLoad,
      filters,
      status,
      requestExport,
      totalRequest,
      limit,
      userRequestStatus,
      filterClone,
    };
  },
});
